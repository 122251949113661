body {
  background: transparent; /* Make it white if you need */
  padding: 0 24px;
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  color: #72a24d;
}

#astronaut {
  width: 43px;
  animation: hover 2s infinite ease-in-out alternate;
  margin-left: auto;
}

#planet {
  width: 900px;
  position: fixed;
}

.saturn,
.saturn-2,
.hover {
  animation: hover 2s infinite ease-in-out alternate;
}

@keyframes hover {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}
